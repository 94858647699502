<template>
    <div id="wrap">
        <Header/>
        <router-view/>
        <Footer/>
    </div>
</template>
<script>
    import Header from '@/components/header.vue';
    import Footer from '@/components/footer.vue';
    export default {
        name: 'Home',
        components: {
            Header,
            Footer
        }
    }
</script>
