<template>
    <header class="main-header" data-react-to-megamenu="true" data-sticky-header="true" data-sticky-options='{ "stickyTrigger": "first-section" }'>

        <div class="mainbar-wrap bg-fade-white-08">

            <span class="megamenu-hover-bg"></span>

            <div class="container mainbar-container">
                <div class="mainbar">
                    <div class="row mainbar-row align-items-lg-stretch px-4">

                        <div class="col-auto">

                            <div class="navbar-header">
                                <a class="navbar-brand" href="/" rel="home">
										<span class="navbar-brand-inner">
											<img class="logo-default" src="/logo.png"  width="200" alt="Ave HTML Template">
										</span>
                                </a>
                                <button type="button" class="navbar-toggle collapsed nav-trigger style-mobile" data-toggle="collapse" data-target="#main-header-collapse" aria-expanded="false" data-changeclassnames='{ "html": "mobile-nav-activated overflow-hidden" }'>
                                    <span class="sr-only">Toggle navigation</span>
                                    <span class="bars">
											<span class="bar"></span>
											<span class="bar"></span>
											<span class="bar"></span>
										</span>
                                </button>
                            </div><!-- /.navbar-header -->


                        </div><!-- /.col -->

                        <div class="col text-right">

                            <div class="collapse navbar-collapse" id="main-header-collapse">

                                <ul id="primary-nav" class="main-nav nav align-items-lg-stretch justify-content-lg-end" data-submenu-options='{ "toggleType":"fade", "handler":"mouse-in-out" }'>

                                    <li>
                                        <a href="/">
                                            <span class="link-icon"></span>
                                            <span class="link-txt">
                                                <span class="link-ext"></span>
                                                <span class="txt">
                                                    Home
                                                    <span class="submenu-expander">
                                                        <i class="fa fa-angle-down"></i>
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/about">
                                            <span class="link-icon"></span>
                                            <span class="link-txt">
                                                <span class="link-ext"></span>
                                                <span class="txt">
                                                    About
                                                    <span class="submenu-expander">
                                                        <i class="fa fa-angle-down"></i>
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/services">
                                            <span class="link-icon"></span>
                                            <span class="link-txt">
                                                <span class="link-ext"></span>
                                                <span class="txt">
                                                    Services
                                                    <span class="submenu-expander">
                                                        <i class="fa fa-angle-down"></i>
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/contact-us">
                                            <span class="link-icon"></span>
                                            <span class="link-txt">
                                                <span class="link-ext"></span>
                                                <span class="txt">
                                                    Contact Us
                                                    <span class="submenu-expander">
                                                        <i class="fa fa-angle-down"></i>
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>

                                </ul><!-- /#primary-nav  -->

                            </div><!-- /#main-header-collapse -->


                        </div><!-- /.col -->

                    </div><!-- /.mainbar-row -->
                </div><!-- /.mainbar -->
            </div><!-- /.mainbar-container -->
        </div><!-- /.mainbar-wrap -->

    </header>
</template>
