<template>
    <footer class="main-footer pt-0" data-sticky-footer="true">

        <section class="bt-fade-white-015 pt-20 pb-20">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-6">

                        <ul class="lqd-custom-menu reset-ul inline-nav">
                            <li><a href="#">About</a></li>
                            <li><a href="library.html">Services</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>

                    </div><!-- /.col-md-6 -->

                    <div class="lqd-column col-md-6 text-md-right">
                        <p class="my-0"><span style="font-size: 15px;">© {{new Date().getFullYear()}} Visiontechers</span></p>
                    </div><!-- /.col-md-6 text-md-right -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

    </footer>
</template>
